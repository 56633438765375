<template>
  <div>
    <h3 class="my-4 primary-text--text modal-title ">
      {{ $t("labels.registration_data") }}
    </h3>

    <div class="my-5">
      <p class="my-3 secondary-text--text modal-title ">
        {{ $t("labels.general_data") }}
      </p>
      <v-row>
        <v-col md="4" cols="12">
          <div class="d-flex flex-column content-responsive">
            <p class="ma-0 secondary-text--text">
              {{ $t("labels.name") }}
            </p>
            <p class="ma-0 primary-text--text">
              {{ productiveUnit.nome_propriedade || defaultEmptyData }}
            </p>
          </div>
        </v-col>
        <v-col md="4" cols="12">
          <div class="d-flex flex-column content-responsive">
            <p class="ma-0 secondary-text--text">
              {{ $t("labels.association") }}
            </p>
            <p class="ma-0 primary-text--text">
              {{ productiveUnit.associacao.nome || defaultEmptyData }}
            </p>
          </div>
        </v-col>
        <v-col md="4" cols="12">
          <div class="d-flex flex-column content-responsive">
            <p class="ma-0 secondary-text--text">
              {{ $t("labels.status", { certificationType: null }) }}
            </p>
            <p class="ma-0 primary-text--text">
              {{ productiveUnit.status || defaultEmptyData }}
            </p>
          </div>
        </v-col>
      </v-row>
    </div>

    <v-divider />

    <div class="my-5">
      <p class="my-3 secondary-text--text modal-title ">
        {{ $t("labels.localization_data") }}
      </p>
      <v-row>
        <v-col md="1" cols="12">
          <div class="d-flex flex-column content-responsive">
            <p class="ma-0 secondary-text--text">
              {{ $t("labels.cep") }}
            </p>
            <p class="ma-0 primary-text--text">
              {{ productiveUnit.cep_formatado || defaultEmptyData }}
            </p>
          </div>
        </v-col>
        <v-col md="1" cols="12">
          <div class="d-flex flex-column content-responsive">
            <p class="ma-0 secondary-text--text">
              {{ $t("labels.uf") }}
            </p>
            <p class="ma-0 primary-text--text">
              {{ productiveUnit.uf || defaultEmptyData }}
            </p>
          </div>
        </v-col>
        <v-col md="2" cols="12">
          <div class="d-flex flex-column content-responsive">
            <p class="ma-0 secondary-text--text">
              {{ $t("labels.city") }}
            </p>
            <p class="ma-0 primary-text--text">
              {{ productiveUnit.cidade || defaultEmptyData }}
            </p>
          </div>
        </v-col>
        <v-col md="2" cols="12">
          <div class="d-flex flex-column content-responsive">
            <p class="ma-0 secondary-text--text">
              {{ $t("labels.address") }}
            </p>
            <p class="ma-0 primary-text--text">
              {{ productiveUnit.endereco || defaultEmptyData }}
            </p>
          </div>
        </v-col>
        <v-col md="2" cols="12">
          <div class="d-flex flex-column content-responsive">
            <p class="ma-0 secondary-text--text">
              {{ $t("labels.district") }}
            </p>
            <p class="ma-0 primary-text--text">
              {{ productiveUnit.bairro || defaultEmptyData }}
            </p>
          </div>
        </v-col>
        <v-col md="2" cols="12">
          <div class="d-flex flex-column content-responsive">
            <p class="ma-0 secondary-text--text">
              {{ $t("labels.latitude_dms") }}
            </p>
            <p class="ma-0 primary-text--text">
              {{ productiveUnit.latitude_dms || defaultEmptyData }}
            </p>
          </div>
        </v-col>
        <v-col md="2" cols="12">
          <div class="d-flex flex-column content-responsive">
            <p class="ma-0 secondary-text--text">
              {{ $t("labels.longitude_dms") }}
            </p>
            <p class="ma-0 primary-text--text">
              {{ productiveUnit.longitude_dms || defaultEmptyData }}
            </p>
          </div>
        </v-col>
      </v-row>
    </div>

    <v-divider />

    <div class="my-5">
      <p class="my-3 secondary-text--text modal-title ">
        {{ $t("labels.data_production_or_producer_group") }}
      </p>
      <v-row>
        <v-col md="3" cols="12">
          <div class="d-flex flex-column content-responsive">
            <p class="ma-0 secondary-text--text">
              {{ $t("labels.type") }}
            </p>
            <p class="ma-0 primary-text--text">
              {{ productiveUnit.produtor_ou_grupo.tipo || defaultEmptyData }}
            </p>
          </div>
        </v-col>
        <v-col md="3" cols="12">
          <div class="d-flex flex-column content-responsive">
            <p class="ma-0 secondary-text--text">
              {{ $t("labels.producer_or_group") }}
            </p>
            <p class="ma-0 primary-text--text">
              {{ productiveUnit.produtor_ou_grupo.nome || defaultEmptyData }}
            </p>
          </div>
        </v-col>
      </v-row>
    </div>

    <v-divider />

    <div class="my-5">
      <p class="my-3 secondary-text--text modal-title">
        {{ $t("labels.data_area") }}
      </p>
      <v-row>
        <v-col md="3" cols="12">
          <div class="d-flex flex-column content-responsive">
            <p class="ma-0 secondary-text--text">
              {{ $t("labels.total_area") }}
            </p>
            <p class="ma-0 primary-text--text">
              {{ productiveUnit.area_total || defaultEmptyData }}
            </p>
          </div>
        </v-col>
        <v-col md="3" cols="12">
          <div class="d-flex flex-column content-responsive">
            <p class="ma-0 secondary-text--text">
              {{ $t("labels.cotton_area") }}
            </p>
            <p class="ma-0 primary-text--text">
              {{ productiveUnit.area_de_algodao || defaultEmptyData }}
            </p>
          </div>
        </v-col>
        <v-col md="3" cols="12">
          <div class="d-flex flex-column content-responsive">
            <p class="ma-0 secondary-text--text">
              {{ $t("labels.environmental_protection_area") }}
            </p>
            <p class="ma-0 primary-text--text">
              {{
                productiveUnit.area_de_protecao_ambiental || defaultEmptyData
              }}
            </p>
          </div>
        </v-col>
      </v-row>
    </div>

    <v-divider />
  </div>
</template>

<script>
export default {
  name: "GeneralData",
  props: {
    productiveUnit: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    defaultEmptyData: "Não registrado"
  })
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  .modal-title {
    text-align: center;
    word-break: break-word;
  }

  .content-responsive {
    justify-content: center;
    align-items: center;
  }
}
</style>
