<template>
  <v-row v-if="modalState">
    <v-dialog
      v-model="modalState"
      :persistent="true"
      :retain-focus="false"
      origin="center left"
      width="95%"
      scrollable
    >
      <v-card class="modal">
        <v-card-title class="primary--text modal-title"
          >{{ $t("labels.detail_production_unit") }}
        </v-card-title>

        <v-divider />

        <v-card-text>
          <general-data :productiveUnit="productiveUnit" />
          <registered-enrollments :compositions="productiveUnit.composicoes" />
          <cotton-data :productiveUnit="productiveUnit" />
        </v-card-text>

        <v-divider />

        <v-card-actions class="d-flex justify-center align-center">
          <v-btn text color="primary" @click="close">{{
            $t("labels.close")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import GeneralData from "@/modules/invite-uba/views/components/detail-production-unit/GeneralData";
import RegisteredEnrollments from "@/modules/invite-uba/views/components/detail-production-unit/RegisteredEnrollments";
import CottonData from "@/modules/invite-uba/views/components/detail-production-unit/CottonData";
import { mapActions } from "vuex";

export default {
  name: "DialogDetailProductionUnit",

  components: {
    GeneralData,
    RegisteredEnrollments,
    CottonData
  },

  data: () => ({
    modalState: false,
    defaultEmptyData: "Não registrado",
    productiveUnit: {}
  }),

  methods: {
    ...mapActions(["toggleSnackbar"]),
    ...mapActions("inviteUba", ["fetchProductiveUnitByIdAndHarvestId"]),

    close() {
      this.modalState = false;
    },
    async open(productiveUnitId, harvestId) {
      try {
        const productiveUnit = await this.fetchProductiveUnitByIdAndHarvestId({
          id: productiveUnitId,
          safra: harvestId
        });
        this.productiveUnit = productiveUnit;
        this.modalState = true;
      } catch (error) {
        this.toggleSnackbar({
          text: error?.response?.data?.detail ?? this.$t("text.erro_interno"),
          type: "error"
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  .modal-title {
    justify-content: center;
  }
}
</style>
